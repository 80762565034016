
import http from '@/axios/index';

const getAgreement = id => {
  return http('/cms/pages/' + id)
}

export {
  getAgreement
}
