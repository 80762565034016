
<template>
  <div class="content">
    <div v-html="content"></div>
  </div>
</template>
<script lang="js">
  import { getAgreement } from "@/interfaces/agreement";
  export default {
    data(){
      return{
        title   : '',
        content : '',
      }
    },
    created() {
      if(this.$route.params.id == 1 || this.$route.params.id == 2){
        getAgreement(this.$route.params.id).then(res => {
          let { title, content } = res;
          this.title = title
          this.content = content
        })
        return
      }
      this.$router.replace({name: '404'})
    }
  }
</script>

<style scoped>
  .content{ padding: 20px; font-size: 14px; color: #333; width: 100vw; box-sizing: border-box; }
  .content *{ word-break: break-all; word-wrap: break-word; }
</style>

