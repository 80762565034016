
import axios from "axios";
import store from "@/store/index"
import { Toast } from 'vant'

const http = axios.create({
    baseURL: process.env.VUE_APP_HOST
})

// 请求拦截器
http.interceptors.request.use(config => {
    config.headers['Authorization'] = store.getters.getToken || localStorage.getItem('token')
    return config;
}, error => {
    return Promise.reject(error);
});

// 响应拦截器
http.interceptors.response.use(response => {
    let { status } = response
    if(status === 200){
        let { data } = response
        let statusCode   = data.status_code
        let responseData = data.data
        if(statusCode === 200){
            return responseData;
        }
        if(statusCode === 401){
            Toast('token过期')
        }
        if(statusCode === 404){
            Toast('请求地址错误，错误码404')
        }
        if(status === 502){
            Toast('服务器错误，错误码502')
        }
        return Promise.reject(data)
    }
}, error => {
    return Promise.reject(error);
});

export default http
